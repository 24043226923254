import { render, staticRenderFns } from "./index.vue?vue&type=template&id=37e50170&scoped=true&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "../../stylus/reset.styl?vue&type=style&index=0&lang=stylus&"
import style1 from "./index.vue?vue&type=style&index=1&id=37e50170&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37e50170",
  null
  
)

export default component.exports