<template lang="pug">
#opinion
    textarea.message(
        placeholder="请输入你要提交的内容",
        maxlength="150",
        v-model="form.content"
    )
    //- .txtNum 
    //-     span.num#num 0
    //-     span.num/150
    input.phone(placeholder="请输入你的手机号", v-model="form.mobile")
    .submit.active(@click="submitForm") 提交
    //输入状态
    //- .sumit.active 提交
</template>
 
<style src="../../stylus/reset.styl" lang="stylus"></style>
<script>
import { feedbackSave } from "../../config/request";
import ShareImpl from '@/weixinApi.js';


export default {
    props: {},
    data() {
        return {
            form: {
                content: "",
                mobile: "",
            },
        };
    },
    async created() {
        let shareUrl = window.location.href;
        // if(shareUrl.indexOf('from') != -1){
        //   shareUrl = window.location.href.split("?")[0];
        // }
        let res = {data:{}};
        // window.console.log(url + 'favicon.png');
        console.log(res,11111);
        let img = window.location.href.split("#")[0] + 'd.jpg';
        let shareTitle = '米链谷川';
        let shareDesc = res.data.share ? res.data.share.share_title : '意见反馈';
        console.log(shareDesc,'分享title')
        let shareImg =  img;
        // let shareImg = img;
        console.log(shareImg,'图片111')
        console.log(img,'本地111')
        ShareImpl({
          shareTitle,
          shareDesc,
          shareUrl: location.origin + location.pathname + "?share=true/" + location.hash,
          shareImg
        });
    },
    methods: {
        // wordStatic(input) {
        // // 获取要显示已经输入字数文本框对象
        // console.log(input,"qqq")
        // var content = document.getElementById('num');
        // if (content && input) {
        //     // 获取输入框输入内容长度并更新到界面
        //     var value = input.value;
        //     // 将换行符不计算为单词数
        //     value = value.replace(/\n|\r/gi,"");
        //     // 更新计数
        //     content .innerText = value.length;
        // }
    // },
        //重置
        resetTemp() {
            this.form = {
                content: "",
                mobile: "",
            };
        },
        submitForm() {
            if (this.form.content == "") {
                return this.$toast({ message: "请输入你要提交的内容" });
            }
            if (this.form.contact_phone == "") {
                return this.toast({ message: "请输入你的手机号" });
            }
            let data = {
                content: this.form.content,
                mobile: this.form.mobile,
            };
            
            feedbackSave(data).then((res) => {
                if (res.code === 20000) {
                    this.toast({ message: res.data });
                    this.resetTemp();
                    
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.message {
    height: 150px;
    line-height: 26px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 14px 12px;
    background: #ffffff;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    color: #333333;
    position relative
}

.phone {
    width: 100%;
    padding: 13px 12px;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    color: #333333;
}

.submit {
    margin: 30px 12px;
    height: 44px;
    background: #E6E6E6;
    border-radius: 22px;
    line-height: 44px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
}

.active {
    background: #4375F2;
    margin: 30px 12px;
    height: 44px;
    border-radius: 22px;
    line-height: 44px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
}
.txtNum{
    position: absolute; 
    right: 12px;
    bottom: 20px;
    color:#CCCCCC;
    font-size: 14px;
}
</style>